import { DateTimeFormats } from 'vue-i18n'

export const messages = {
  en: {
    pageTitles: {
      arrangedPayments: 'Arranged Payments',
      billDetail: 'Your Bill',
      bills: 'Bills',
      checkEmailPrompt: 'Check Your Email',
      checkMessagesPrompt: 'Check Your Text Messages',
      confirmPin: 'Confirm Your Pin',
      congratulations: 'Congratulations!',
      createPin: 'Create Your Pin',
      enterEmailPrompt: 'Enter Your Email', // used on forgot Auth page
      enterPhonePrompt: 'Enter Your Phone Number', // used on forgot Auth page
      login: 'Welcome',
      linkExpiredPrompt: 'This link is expired',
      messageSettings: 'Communication Preferences',
      newCustomerRegistration: 'Register Your Account',
      notFound: 'Page Not Found',
      paymentMethods: 'Payment Methods',
      receipts: 'Receipts',
      resetEmail: 'Reset Email',
      resetPin: 'Reset Pin',
      sendNewLinkPrompt: 'Enter Your Phone Number',
      setNewPassword: 'Set New Password',
      setNewPasswordSuccess: 'Successfully Set Password',
      setNewPin: 'Set New Pin',
      setNewPinSuccess: 'Successfully Set Pin',
      setUpPayments: 'Set Up Your Payments',
      thankYou: 'Thank You',
      cashTitle: 'How to pay with cash:',
      cashBannerAlt: 'Cash Retails'
    },
    drawerTitles: {
      addACH: 'Add Bank Account',
      addCard: 'Add a Card',
      deletePaymentMethod: 'Delete Payment Method',
      editACH: 'Edit Bank Account',
      editCard: 'Edit Card',
      multipayConfirmation: 'Confirm Multi-Card Payment',
      paymentConfirmation: 'Confirm Payment',
      paymentMethod: 'Payment Method',
      paymentMethodAccounts: 'Account(s) using payment method',
      paymentMethodPicker: 'Add a Payment Method',
      schedulePayment: 'Schedule A Payment',
      setupAutopay: 'Setup Auto Pay',
      cashTermsTitle: 'Terms'
    },
    labels: {
      accountName: 'Account Name',
      accountType: 'Account Type',
      addPaymentMethod: 'Add a Payment Method',
      amountDue: 'Amount Due',
      amountScheduled: 'Amount Scheduled',
      selectedPromisesAmount: 'Selected Promises Amount',
      approved: 'Approved',
      autoPay: 'Auto Pay',
      autopayDisabled: 'Autopay Disabled',
      autopayEnabled: 'Autopay Enabled',
      billAmount: 'Total',
      billing: 'Billing',
      convenienceFee: 'Convenience Fee',
      convenienceFeeWaived: 'Convenience Fee: Waived',
      customerService: 'Customer Service',
      declined: 'Declined',
      defaultPaymentMethod: 'Default Payment Method',
      dueDate: 'Due Date',
      email: 'Email',
      fee: 'Fee',
      lastFour: 'Last Four',
      logout: 'Logout',
      marketing: 'Marketing',
      multipay: 'Multi Pay',
      nickname: 'Nickname',
      password: 'Password',
      passwordConfirm: 'Confirm Password',
      paymentAmount: 'Payment Amount',
      paymentType: 'Payment Type',
      phone: 'Phone Number',
      poweredBy: 'Powered By',
      processedDate: 'Processed Date',
      rejected: 'Rejected',
      routingNumber: 'Routing Number',
      scheduledAmount: 'Scheduled Amount',
      scheduledDate: 'Scheduled Date',
      scheduledPayment: 'Scheduled Payment',
      selectLangauge: 'Language',
      sms: 'Text',
      total: 'Total',
      totalAlreadyPaid: 'Already Paid',
      unnamed: 'UNNAMED',
      EMAIL: 'Email',
      MARKETING: 'Marketing',
      PAYMENT: 'Payment',
      SUPPORT: 'Support',
      TEXT: 'Text',
      billAmountCash: 'Pay this Amount With Cash',
      balance: 'Balance :'
    },
    achLabels: {
      accountNickName: 'Account Nickname',
      accountNumber: 'Account Number',
      accountType: 'Account Type',
      nameOnAccount: 'Name on Account',
      routingNumber: 'Routing Number',
      selectTypePrompt: 'Please Select Account Type',
      typeChecking: 'Checking',
      typeSavings: 'Savings',
      useForFuturePayments:
        'Allow merchant to use this payment method for future payments'
    },
    cardLabels: {
      cardNickName: 'Card Nickname',
      cardNumber: 'Card Number',
      cvv: 'CVV',
      expiration: 'Expiration',
      nameOnCard: 'Name on Card',
      postalCode: 'Postal Code',
      streetAddress: 'Street Address',
      useForFuturePayments:
        'Allow merchant to use this card for future payments'
    },
    cashLabels: {
      termsCashSubject: 'Click Here for Terms & Conditions',
      cashInfo:
        'Terms and conditions and fees info and all that will go here in this space.',
      termsFirstParagraph:
        'By accepting or using this barcode to make a payment you agree to full terms and conditions',
      termsSecondtParagraph:
        'After successful payment using this barcode, you may retrieve your full detailed e-receipt at',
      info: {
        firstParagraph:
          '1. Find a nearby retail location using the Location Finder.',
        secondParagraph: '2. Generate Barcode for your payment.',
        thirdParagraph: '3. Provide your barcode and cash to the cashier.',
        fourthParagraph:
          '4. Make your payment, funds will be applied to your account within moments and your payment will be complete.'
      }
    },
    buttons: {
      addACH: 'Add Bank Account',
      addCard: 'Add Card',
      addMoreCards: 'Add Another Card',
      arrangePayments: 'Arrange Payments',
      back: 'Back',
      backToLogin: 'Back To Login',
      cancel: 'Cancel',
      cancelScheduled: 'Cancel Scheduled Payment',
      close: 'Close',
      delete: 'Delete',
      home: 'Home',
      invoice: 'View Invoice',
      login: 'Login',
      loginHelp: 'Trouble Logging In?',
      manuallySendPaymentLink: 'Please click to send a new payment link',
      makePayment: 'Pay Now!',
      next: 'Next',
      nextPayment: 'Next Payment',
      open: 'Open',
      register: 'Register',
      resetPin: 'Reset My Pin',
      save: 'Save',
      schedulePayment: 'Schedule A Payment',
      schedulePaymentUpdate: 'Update Scheduled Payments',
      selectPayment: 'Select Payment Method',
      //   sendLoginLink: 'Send Login Link',
      sendMeLoginLink: 'Send me a link',
      setNewPassword: 'Reset My Password',
      sendPaymentLink: 'send a new payment link',
      setNewPin: 'Set New Pin',
      setPayments: 'Set Payments',
      submitPayments: 'Confirm Payments',
      toLogin: 'Go To Login',
      toBills: 'Go To Bills',
      update: 'Update',
      updateAutopay: 'Update Auto Pay',
      generateBarcode: 'Generate Barcode',
      findLocation: 'Location Finder',
      refreshBarcode: 'Refresh Barcode (15 minutes)'
    },
    mainNavigation: {
      bills: 'Bills',
      messageSettings: 'Communication Preferences',
      paymentMethods: 'Payment Methods',
      receipts: 'Receipts'
    },
    messages: {
      addAMethod: 'Please add a payment method',
      cancelPromise:
        'The above payment(s) will automatically run on the displayed dates. Please contact your merchant to cancel or modify payments.',
      cancelPromiseCash:
        'The above payment(s) will automatically run on the displayed dates. Please contact your merchant to cancel, modify payments or pay with cash.',
      checkEmailRegistrationPrompt:
        'Please check your email for instructions on how to complete registration.',
      checkEmailResetPasswordPrompt:
        'Please check your email for instructions on how to set a new password.',
      checkPhoneResetPasswordPrompt:
        'Please check your text messages for instructions on how to set a new pin.',
      checkTextMessagesPromptAccount:
        'If the this account matches our records you will receive a text message with a login link.',
      checkTextMessagesPromptPhone:
        'If the phone number entered matches our records you will receive a text message with a login link.',
      deletePaymentMethodText:
        'Deleting this payment method will affect everything listed below. Are you sure you want to delete it?',
      noPaymentSelected: 'No Payment Selected',
      noReceipt: 'No Receipts Yet',
      paymentSuccess:
        'Your Payment of <strong>{receiptAmount}</strong> Was successful!',
      pinSuccess: `
      <p>
        You have successfully reset your pin, please go to your messages and
        follow a bill link to access your pin login.
      </p>
      <p>Or</p>
      <p>
        Set up a password to login directly.
      </p>
      `,
      promiseVerify:
        'By clicking submit, you agree to be charged the specified amount to each payment method on the scheduled dates.',
      promisesPayInfo:
        'The selected payments will be processed immediately upon clicking the "Pay Now" button.',
      promiseVerifyCash:
        'By clicking submit, you agree to contact the merchant to pay the amount listed in cash.',
      promiseVerifyPartial:
        'By clicking submit, you agree to be charged the specified amount to each payment method on the scheduled dates and contact the merchant to pay the cash amount listed.',
      resetPinSuccess: `
        <p>
          You have successfully reset your pin, please go to your messages and
          follow a bill link to access your pin login.
        </p>
        <p>Or</p>
        <p>
          Set up a password to login directly.
        </p>
      `,
      resetPasswordSuccess: 'You have successfully reset your password.',
      registrationSuccess:
        'You have successfully registered, please use your {method} to login.',
      requiresPaymentMethod: 'Requires Payment Method',
      sendLinkManually: 'Please click the button below to send a new one.',
      unableToDeletePaymentMethodText:
        'Unable to delete the payment method because you have a promised payment. Please contact your merchant to cancel or modify payments.',
      disableMerchantOnlyAutopayTitle: 'Access Denied',
      disableMerchantOnlyAutopay:
        'Contact your loan provider to disable autopay.',
      enableMerchantOnlyAutopayTitle: 'Enable Autopay?',
      enableMerchantOnlyAutopay:
        'If you turn on the auto pay, to turn it off you need to contact your loan provider.',
      balanceDisclaimerLine1:
        'Your balance may not reflect your full payoff amount. Your payoff amount also includes any interest you accrue through the day you pay off your loan, and may also include other fees you have incurred and have not yet paid.',
      balanceDisclaimerLine2:
        'Payments may not be reflected in the displayed Balance amount until the next business day.'
    },
    legal: {
      autopayScript:
        'When you enroll in AutoPay, your bill for {accountName} will be paid automatically for the total amount on each selected date with the following payment method.',
      termsOfService: `
      <p class="legal">
        I agree to receive bill pay reminder alerts from BlytzPay for
        {merchantName}. See
        <a
          href="https://www.blytzpay.com/terms-and-conditions/"
          target="_blank"
        >
          Terms &amp; Privacy</a
        >.
      </p>
      <p class="legal">
        By registering, I authorize BlytzPay to send me bill-pay
        reminders and payment receipts via SMS &amp; email. Message and
        data rates may apply.
      </p>
      `
    },
    errors: {
      account: {
        already_paid: 'This account has already been paid',
        current_invoice_required: 'Account requires a current invoice',
        external_id: {
          clash: 'Account with provided external ID already exists'
        },
        due_date: {
          autopay_cap: 'Due date with Autopay must not exceed the 28th'
        }
      },
      amountBetween: 'Total must be {min}',
      autopay: {
        already_configured: 'Autopay is already setup by another customer'
      },
      card_number: {
        too_long: 'card_number can be at most 16 characters',
        too_short: 'card_number must be at least 15 characters'
      },
      card_type: {
        invalid: 'Invalid card type'
      },
      customer: {
        shared_secret_incorrect:
          'Provided information does not match customer record'
      },
      exactTotal: 'Payment amount must be {total}',
      invalidCVV2: 'Must be 3 or 4 digits',
      invalidEmail: 'Must be a valid email',
      invalidPassword:
        'Must be at least 8 characters long and contain each of the following: one lowercase, one uppercase, one numeric, one special character',
      invalidYearDate: 'Invalid MM/YY',
      maxLength: 'Max length of {max} characters',
      minAmount: 'Must be at least {min}',
      maxAmount: 'Can not exceed {max}',
      passwordMatch: 'Passwords do not match',
      required: 'This field is required',
      ssn_invalid_format: 'SSN must be 9 digits, no dashes (-)',
      location: {
        customer_account_mismatch:
          'Customer does not belong to provided merchant location'
      },
      password: {
        already_set: 'Password has already been set',
        mismatch: 'Passwords do not match'
      },
      payment: {
        already_scheduled: 'Payment is already scheduled for this account',
        beyond_due_date: 'Cannot schedule payment beyond due date',
        minimum: 'Payment must be larger than $1.00'
      },
      payment_amount: {
        does_not_match: 'Combined amounts do not match the amount due',
        too_large:
          'Requested payment amount is greater than the maximum allowed',
        too_small: 'Requested payment amount is less than the minimum allowed'
      },
      payment_method: {
        not_found: 'Invalid payment method specified for autopay'
      },

      pin: {
        mismatch: 'Pins do not match'
      },
      template: {
        schedule_clash: 'A template already exists for the chosen days'
      },
      token: {
        invalid: 'Invalid Token'
      },
      user: {
        email_in_use: 'Email address already in use',
        not_found: 'No user found with matching credentials',
        org_already_set: 'User already belongs to an organization'
      }
    },
    registrationFields: {
      address: 'Address',
      address2: 'Address 2',
      city: 'City',
      company_name: 'Company Name',
      country: 'Country',
      date_of_birth: 'Date of Birth',
      email: 'Email',
      external_id: 'Account ID',
      first_name: 'First Name',
      language: 'Language',
      last_name: 'Last Name',
      postal_code: 'Postal Code',
      sms_phone_number: 'Phone Number',
      ssn_last_four: 'Last Four of SSN',
      state_province: 'State / Province'
    },
    statuses: {
      declined: 'Declined',
      delivered: 'Delivered',
      failed: 'Failed',
      finished: 'Finished',
      ok: 'Success',
      paid: 'Paid',
      pending: 'Pending',
      promised: 'Promised',
      promise_confirmed: 'Confirmed Promise',
      refund: 'Refund',
      rejected: 'Rejected',
      running: 'Running',
      skipped: 'Skipped',
      void: 'Void'
    }
  }
}

export const numberFormats = {
  en: {
    currency: {
      currency: 'USD',
      style: 'currency'
    }
  }
}

export const dateTimeFormats: DateTimeFormats = {
  en: {
    short: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    },
    long: {
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      month: 'long',
      weekday: 'long',
      year: 'numeric'
    }
  }
}
