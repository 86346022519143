import Vue from 'vue'
import Component from 'vue-class-component'
import { Dict } from '@/utils/interfaces'
import { normalizeSnakeCase } from '@/utils/functions'
class Error {
  title: string
  message: string

  constructor(message: string, title = 'Error') {
    this.title = title
    this.message = message
  }
}

interface FormatterFunc {
  (response: Dict): Dict
}

export interface APIErrorOptions {
  formatter: FormatterFunc
}

@Component
export class HandleErrorsMixin extends Vue {
  handleAPIErrors(response: Dict, options?: APIErrorOptions) {
    let errors: Error[] = []

    // If caller provided a formatter, format the response
    // formatted data should come back with a list of flattened strings.
    if (options?.formatter) {
      response = options.formatter(response)
    }

    // add an error detail, should be a flat string already
    if (response.data.detail) {
      errors.push(new Error(response.data.detail))
    }

    // iterate over and flatten non field errors
    if (response.data.non_field_errors) {
      errors = errors.concat(
        response.data.non_field_errors.map((x: string) => new Error(x))
      )
    }

    // Take a list of objects and flatten it for display
    if (!response.data.non_field_errors && typeof response.data === 'object') {
      const flattenedErrors = Object.entries(response.data)
        .map(([key, value]) => {
          if (['non_field_errors', 'detail'].includes(key)) {
            return null
          } else {
            return new Error(`${normalizeSnakeCase(key)} ${value}`)
          }
        })
        .filter(x => x !== null)

      errors = errors.concat(flattenedErrors)
    }

    for (const err of errors) {
      this.toastError(err.message as string, err.title)
    }
  }

  toastError(message: string, title: string) {
    this.$bvToast.toast(this.$t(message) as string, {
      title: this.$t(title) as string,
      toaster: 'b-toaster-bottom-center',
      noAutoHide: true,
      appendToast: true,
      variant: 'danger'
    })
  }
}
