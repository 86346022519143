import moment from 'moment'
import { AuthState, Dict } from '@/utils/interfaces'
import getDefaultState from './defaultState'

const mutations = {
  resetState(state: AuthState) {
    Object.assign(state, getDefaultState())
  },

  setTokens(
    state: AuthState,
    {
      accessToken,
      refreshToken
    }: { accessToken: string | null; refreshToken: string | null }
  ) {
    state.accessToken = accessToken
    state.refreshToken = refreshToken

    if (accessToken) {
      localStorage.setItem('blytzpay_access_token', accessToken)
    } else if (accessToken === null) {
      localStorage.removeItem('blytzpay_access_token')
    }

    if (refreshToken) {
      localStorage.setItem('blytzpay_refresh_token', refreshToken)
    } else if (refreshToken == null) {
      localStorage.removeItem('blytzpay_refresh_token')
    }
  },

  setLastActivity(state: AuthState) {
    /*
     Sets the last activity as a utc unix timestamp
     This method should mostly be called from an axios interceptor
    */
    state.lastActivity = moment()
      .utc()
      .unix()
  },

  setIsInactive(state: AuthState, isInactive: boolean) {
    state.isInactive = isInactive
  },

  setProfile(state: AuthState, { profile }: { profile: Dict }) {
    state.profile = profile
  },

  setHeartbeat(state: AuthState, heartbeat: number) {
    state.heartbeat = heartbeat
  }
}

export default mutations
