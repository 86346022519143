<template>
  <div v-if="linkData">
    <router-link
      :to="linkData.to"
      class="menu-item"
      @click.native="handleClick"
      :data-cy="linkData.e2eLabel"
      v-slot="{ navigate, isExactActive, href }"
      custom
    >
      <div v-if="linkData.subItems">
        <span class="icon-container">
          <fa-icon :icon="linkData.icon" />
        </span>
        <span class="label">{{ linkData.label }}</span>
        <span class="extra" v-if="linkData.subItems" :class="caretClass">
          <fa-icon :icon="['fas', 'caret-left']" />
        </span>
      </div>

      <div
        v-else
        @click="navigate"
        :active="isExactActive"
        :href="href"
        :class="{ 'router-link-exact-active': isExactActive }"
      >
        <span class="icon-container">
          <fa-icon :icon="linkData.icon" />
        </span>
        <span class="label">{{ linkData.label }}</span>
      </div>
    </router-link>
    <div
      ref="subMenu"
      class="sub-items"
      :style="subMenuStyles"
      v-if="linkData.subItems"
    >
      <div v-for="item of linkData.subItems" :key="item.label">
        <router-link :to="item.to" class="menu-item">
          {{ item.label }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    linkData: {
      type: Object,
      required: true
    }
  },
  mounted() {
    if (this.$refs.subMenu) {
      this.subMenuHeight = this.$refs.subMenu.scrollHeight
    }
  },
  data() {
    return {
      isOpen: false,
      subMenuHeight: 0
    }
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen
    },
    handleClick(e) {
      if (this.$props.linkData.subItems) {
        e.preventDefault()
        this.toggleOpen()
      }
      if (this.linkData.to.name === this.$route.name) this.$emit('closeMenu')
    }
  },
  computed: {
    caretClass() {
      return this.isOpen ? 'opened' : ''
    },
    subMenuStyles() {
      return {
        height: this.isOpen ? `${this.subMenuHeight}px` : '0px'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-item {
  color: $white;
  padding: 10px;
  margin: 10px 15px;
  transition: 200ms background-color ease-in-out;
  background-color: transparent;
  font-family: $accent-font-family;

  color: $white;
  display: flex;
  align-items: center;

  &.router-link-exact-active {
    background: #3a4f68;
    box-shadow: 0px 3px 6px #00000087;
    border-radius: 6px;
  }

  &:hover {
    background-color: darken($blue, 10%);
    cursor: pointer;
  }
}

.icon-container {
  margin: 0 15px;
  .svg {
    font-size: 21px;
    margin-right: 25px;
  }
}

.label {
  flex: 1;
  text-decoration: none;
}

.sub-items {
  color: $white;
  background: darken($blue, 10%);
  overflow: hidden;
  transition: height 222ms ease-in-out;
}

.sub-item {
  margin-left: 50px;
  font-weight: bold;
  padding: 15px 0 15px 15px;
  background-color: transparent;
  transition: 200ms background-color ease-in-out;

  &.active {
    background-color: darken($blue, 15%);
  }

  &:hover {
    background-color: lighten($blue, 10%);
    cursor: pointer;
  }
}

.extra {
  transition: transform 222ms ease-in-out;

  &.opened {
    transform: rotate(-90deg);
  }
}
</style>
