import mutations from './mutations'
import getters from './getters'
import getDefaultState from './defaultState'
import actions from './actions'

const state = getDefaultState()

const store: any = {
  state: () => state,
  mutations,
  getters,
  actions
}

export default store
