import Vue from 'vue'
import Vuex from 'vuex'
import authStore from './authstore/index'
import dataStore from './datastore/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { authStore, dataStore }
})
