



































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Logo from '@/components/Elements/Logo/Logo.vue'
import BlytzLogo from '@/components/Elements/Logo/BlytzLogo.vue'
import NavigationMenu from '@/components/NavigationMenu/NavigationMenu.vue'
import { debounce } from 'lodash-es'
import Drawers from '@/components/Elements/Drawer/Drawers.vue'
import { mapGetters, mapMutations } from 'vuex'

@Component({
  components: {
    BlytzLogo,
    Logo,
    NavigationMenu,
    Drawers
  },
  methods: {
    ...mapMutations(['setBusyCount'])
  },
  computed: {
    ...mapGetters(['accessClaims', 'busyCount', 'isAuthenticated'])
  }
})
export default class Page extends Vue {
  navOpened = false
  isBusy = false
  setBusyCount!: Function
  busyCount: Function
  pageKey = 0

  mounted() {
    const pageContent = document.querySelector('.page-content')
    pageContent.addEventListener('scroll', (e: any) => {
      // automatically close if content is dragged far enough to left and reset
      if (e.target.scrollLeft > 320) {
        this.navOpened = false
        this.pageKey + 1
      }
    })
  }

  toggleNav() {
    this.navOpened = !this.navOpened
  }

  busyChecker() {
    this.isBusy = Boolean(this.busyCount())
  }
  debouncedBusyChecker = debounce(this.busyChecker, 200, { trailing: true })
  @Watch('busyCount')
  busyCountChanged() {
    this.debouncedBusyChecker()
  }
  get showNav(): boolean {
    const showNav = this.$route.meta.hideNav
    return typeof showNav !== undefined ? showNav : true
  }

  closeNav() {
    this.navOpened = false
  }
}
