









import { Component, Vue } from 'vue-property-decorator'
import { mapGetters, mapMutations } from 'vuex'
@Component({
  computed: {
    ...mapGetters(['drawers'])
  },
  methods: {
    ...mapMutations(['popDrawer'])
  }
})
export default class DrawersContainer extends Vue {
  drawers!: Function
  get hasContent() {
    return Boolean(this.drawers().length)
  }
}
