

















import { Component, Vue } from 'vue-property-decorator'
import { debounce } from 'lodash-es'

import BlytzLogo from '@/components/Elements/Logo/BlytzLogo.vue'
const NavigationMenu = () =>
  import('@/components/NavigationMenu/NavigationMenu.vue')

@Component({
  components: {
    BlytzLogo,
    NavigationMenu
  }
})
export default class PageContainer extends Vue {
  busyCount = 0
  isBusy = false

  created() {
    this.$eventBus.on('incrementBusy', this.incrementBusy)
    this.$eventBus.on('decrementBusy', this.decrementBusy)
  }

  incrementBusy() {
    this.busyCount += 1
    this.debouncedBusyStarter()
  }

  busyStart() {
    if (this.busyCount) this.isBusy = true
  }

  decrementBusy() {
    this.busyCount -= 1
    this.debouncedBusyChecker()
  }

  busyChecker() {
    this.isBusy = Boolean(this.busyCount)
  }

  debouncedBusyStarter = debounce(this.busyStart, 200, { trailing: true })
  debouncedBusyChecker = debounce(this.busyChecker, 200, { trailing: true })

  get showNav(): boolean {
    const showNav = this.$route.meta.hideNav
    return typeof showNav !== undefined ? showNav : true
  }

  get variant(): string {
    return this.$route?.meta?.pageVariant || ''
  }
}
