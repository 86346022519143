import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { messages, numberFormats as nF, dateTimeFormats } from '@/locales/en'

Vue.use(VueI18n)

/*
USD is the only currency we have, 
but I don't want to hinder putting other currencies in the
 translation files for when we eventually have conversions or something.
*/
const numberFormats = {
  en: {
    currency: {
      style: 'currency',
      currency: 'USD'
    }
  },
  ...nF
}

export const i18n = new VueI18n({
  locale: 'en', // set locale
  messages,
  numberFormats,
  dateTimeFormats
})

const loadedLanguages = ['oo'] // our default language that is preloaded

function setI18nLanguage(lang: string) {
  i18n.locale = lang

  document.querySelector('html').setAttribute('lang', lang)

  return lang
}

export function loadLanguageAsync(lang: string) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  return import(
    /* webpackChunkName: "lang-[request]" */ `@/locales/${lang}`
  ).then(module => {
    console.log({ module })
    i18n.setLocaleMessage(lang, module.messages[lang])
    i18n.setNumberFormat(lang, module.numberFormats[lang])
    i18n.setDateTimeFormat(lang, module.dateTimeFormats[lang])
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}
