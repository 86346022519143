import { i18n } from '@/locales/i18n-setup'

export default function() {
  return [
    {
      label: i18n.t('mainNavigation.bills'),
      to: { name: 'AccountList' },
      icon: ['fas', 'usd-circle'],
      e2eLabel: 'bills'
    },
    {
      label: i18n.t('mainNavigation.receipts'),
      to: { name: 'PaymentList' },
      icon: 'file-invoice-dollar',
      e2eLabel: 'receipts'
    },
    {
      label: i18n.t('mainNavigation.messageSettings'),
      to: { name: 'MessageSettings' },
      icon: ['fas', 'comments-alt'],
      e2eLabel: 'message-settings'
    },
    {
      label: i18n.t('mainNavigation.paymentMethods'),
      to: { name: 'PaymentMethodList' },
      icon: ['fas', 'wallet'],
      e2eLabel: 'payment-methods'
    },
    {
      label: i18n.t('labels.logout'),
      to: { name: 'Logout' },
      icon: ['fas', 'sign-out-alt'],
      e2eLabel: 'logout'
    }
  ]
}
