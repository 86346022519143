
































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class KeyPad extends Vue {
  @Prop({ default: '' }) readonly value: string = ''

  handleClick(num: string) {
    const newVal = this.value.length < 4 ? this.value + num : this.value
    this.$emit('input', newVal)
  }

  handleDelete() {
    this.$emit('input', this.value?.substring(0, this.value.length - 1))
  }

  get dots(): string[] {
    const filled = this.value.length
    const dots: string[] = new Array(4)
    dots.fill('circle-fill', 0, 4)
    dots.fill('circle', filled, 4)
    return dots
  }
}
