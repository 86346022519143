import { AuthGetters, AuthState, Customer, Dict } from '@/utils/interfaces'
import axios, { anonymousAxios } from '@/utils/blytz-axios'

import endpoints from '@/utils/endpoints'
import moment from 'moment'
import router from '@/router'

const actions = {
  async rehydrate({
    dispatch,
    getters
  }: {
    dispatch: Function
    getters: AuthGetters
  }) {
    if (getters.refreshTokenTimeToLive()) {
      const response = await dispatch('refreshAccess')
      if (response) dispatch('getCustomersAndMerchants')
    }
  },

  async login(
    { dispatch, commit }: { dispatch: Function; commit: Function },
    credentials: Dict
  ) {
    const response = await anonymousAxios.post(
      endpoints.createBlytzTokenObtainPair(),
      credentials
    )

    const data = response.data
    const accessToken = data.access
    const refreshToken = data.refresh

    localStorage.setItem('blytzpay_access_token', accessToken)
    localStorage.setItem('blytzpay_refresh_token', refreshToken)

    commit('setTokens', { accessToken, refreshToken })
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`

    const heartbeat = setInterval(() => {
      dispatch('authHeartbeat')
    }, process.env.VUE_APP_HEARTBEAT_MS)
    commit('setHeartbeat', heartbeat)

    await dispatch('getCustomersAndMerchants')
    return response
  },

  logout({ state, commit }: { state: AuthState; commit: Function }) {
    clearInterval(state.heartbeat)
    commit('setHeartbeat', null)
    commit('setTokens', { accessToken: null, refreshToken: null })
    commit('setIsInactive', false)
    commit('resetState')
    axios.defaults.headers.common = {}
    router.push({ name: 'Login' })
  },

  async refreshAccess({
    commit,
    state,
    getters,
    dispatch
  }: {
    commit: Function
    state: AuthState
    getters: AuthGetters
    dispatch: Function
  }) {
    if (!state.refreshToken) {
      await dispatch('logout')
      return
    }

    const claims = getters.refreshClaims
    if (
      state.refreshToken &&
      claims.exp &&
      moment()
        .utc()
        .unix() < claims.exp
    ) {
      try {
        const response = await axios.post(endpoints.createTokenRefresh(), {
          refresh: state.refreshToken
        })

        const data = response.data
        commit('setTokens', {
          accessToken: data.access,
          refreshToken: data.refresh || state.refreshToken
        })

        axios.defaults.headers.common['Authorization'] = `Bearer ${data.access}`

        return response
      } catch {
        await dispatch('logout')
      }
    }
  },

  authHeartbeat({
    commit,
    state,
    getters,
    dispatch
  }: {
    commit: Function
    state: AuthState
    getters: AuthGetters
    dispatch: Function
  }) {
    /*
    handles idle logout
    Should be called every 15 seconds or so
    */
    if (!getters.isAuthenticated) {
      // if we are not authenticated bail
      return
    }

    const unixNow = moment()
      .utc()
      .unix()
    const sinceLastActivity = unixNow - state.lastActivity
    if (60 * process.env.VUE_APP_SESSION_TIMEOUT < sinceLastActivity) {
      /* Log out if session has timed out */
      dispatch('logout')
    } else if (
      60 * process.env.VUE_APP_SESSION_IDLE < sinceLastActivity &&
      state.isInactive == false
    ) {
      /* Set inactive flag based on session idle time */
      commit('setIsInactive', true)
    } else if (
      0 < getters.refreshTokenTimeToLive() &&
      getters.accessTokenTimeToLive() < 180
    ) {
      /*
      Refresh access if following conditions are met
      - Refresh token is valid
      - access token has less than 50 seconds to live
      */
      dispatch('refreshAccess')
    }
  },

  continueSession({
    dispatch,
    commit
  }: {
    dispatch: Function
    commit: Function
  }) {
    /* Call this when user chooses to continue their session */
    commit('setLastActivity')
    dispatch('refreshAccess')
  },

  async getCustomersAndMerchants({ commit }: { commit: Function }) {
    const response = await axios.get(endpoints.listCustomerRoots(), {
      params: { page_size: 100 }
    })

    const customers = response.data.results
    commit('setCustomers', customers)

    const merchants = new Map(
      customers.map((c: Customer) => [c.merchant.id, c.merchant])
    )

    commit('setMerchants', merchants)

    const accounts = new Map(
      customers
        .map((c: Dict) => c.accounts)
        .reduce((current: Dict, accounts: Dict[]) => {
          return current.concat(accounts)
        }, [])
        .map((account: Dict) => [account.id, account])
    )
    return commit('setAccounts', accounts)
  },

  async updateLanguage(
    { dispatch }: { dispatch: Function; state: AuthState },
    language: string
  ) {
    await axios.post(endpoints.updateLanguage(), { language: language })

    dispatch('getCustomersAndMerchants')
  }
}

export default actions
