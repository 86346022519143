import jwt_decode from 'jwt-decode'
import moment from 'moment'
import { AuthState, AuthGetters } from '@/utils/interfaces'

const getters: AuthGetters = {
  accessTokenTimeToLive(state: AuthState, getters: AuthGetters) {
    // return function as not to cache
    return () => {
      // Get how long until access token expires
      let ttl = 0
      const claims = getters.accessClaims

      if (claims && claims.exp) {
        ttl =
          claims.exp -
          moment()
            .utc()
            .unix()
      }

      return ttl
    }
  },
  refreshTokenTimeToLive(state: AuthState, getters: AuthGetters) {
    // return function as not to cache
    return () => {
      // Get how long until refresh token expires
      let ttl = 0
      const claims = getters.refreshClaims

      if (claims && claims.exp) {
        ttl =
          claims.exp -
          moment()
            .utc()
            .unix()
      }

      return ttl
    }
  },
  isAuthenticated(state: AuthState, getters: AuthGetters) {
    // If the access token is still
    return 0 < getters.accessTokenTimeToLive()
  },
  isInactive(state: AuthState, getters: AuthGetters) {
    return getters.isAuthenticated && state.isInactive
  },
  accessClaims(state: AuthState) {
    if (typeof state.accessToken === 'string') {
      return jwt_decode(state.accessToken)
    }
    return {}
  },
  refreshClaims(state: AuthState) {
    if (typeof state.refreshToken === 'string') {
      return jwt_decode(state.refreshToken)
    }
    return {}
  },
  profile(state: AuthState) {
    return state.profile
  },
  getLanguage: (state: AuthState): Function => (): string => {
    if (state.customers) {
      return state.customers[0].language
    } else {
      return 'en-US'
    }
  }
}

export default getters
