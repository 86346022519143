







import { Component, Vue } from 'vue-property-decorator'
import PageContainer from '@/components/PageContainer/PageContainer.vue'
import Drawers from '@/components/Elements/Drawer/Drawers.vue'

@Component({
  components: {
    PageContainer,
    Drawers
  }
})
export default class App extends Vue {
  mounted() {
    if (window) {
      // Detect if on IOS then apply a special class to
      if (
        [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
      ) {
        document.querySelector('body').classList.add('ios-device')
      }

      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`)

      // We listen to the resize event
      window.addEventListener('resize', () => {
        // We execute the same script as before
        vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      })
    }
  }
}
