import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import {
  AvatarPlugin,
  BadgePlugin,
  ButtonPlugin,
  FormPlugin,
  FormCheckboxPlugin,
  FormInputPlugin,
  FormGroupPlugin,
  FormSelectPlugin,
  CardPlugin,
  SkeletonPlugin,
  SpinnerPlugin,
  ToastPlugin,
  TooltipPlugin,
  BIcon,
  BIconBackspaceFill,
  BIconCircle,
  BIconCircleFill,
  BIconDot,
  ModalPlugin,
  TabsPlugin,
  FormRadioPlugin,
} from 'bootstrap-vue'
import axios from '@/utils/blytz-axios'
import VueAxios from 'vue-axios'

import EndpointPlugin from '@/plugins/endpointPlugin'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeIcon,
  FontAwesomeLayers
} from '@fortawesome/vue-fontawesome'
import iconLibrary from '@/utils/icon-library'
import PortalVue from 'portal-vue'
import eventBus from '@/plugins/event-bus'
import VueMask from 'v-mask'
import VueCurrencyInput from 'vue-currency-input'

import VueCardFormat from 'vue-credit-card-validation'
import { i18n } from '@/locales/i18n-setup'

import '@/utils/common-validators'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/scss/main.scss'
import * as Sentry from '@sentry/vue'
import VueGtag from 'vue-gtag'

// Add selected icons to useable library
library.add(...iconLibrary)

Vue.use(AvatarPlugin)
Vue.use(BadgePlugin)
Vue.use(ButtonPlugin)
Vue.use(FormPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormSelectPlugin)
Vue.use(CardPlugin)
Vue.use(SkeletonPlugin)
Vue.use(SpinnerPlugin)
Vue.use(ToastPlugin)
Vue.use(TooltipPlugin)
Vue.use(ModalPlugin)
Vue.use(TabsPlugin)
Vue.use(FormRadioPlugin)
Vue.component('BIcon', BIcon)
Vue.component('BIconBackspaceFill', BIconBackspaceFill)
Vue.component('BIconCircle', BIconCircle)
Vue.component('BIconCircleFill', BIconCircleFill)
Vue.component('BIconDot', BIconDot)

Vue.config.productionTip = false
Vue.use(EndpointPlugin)
Vue.use(VueAxios, axios)
Vue.use(PortalVue)
Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('fa-layers', FontAwesomeLayers)
Vue.use(eventBus)
Vue.use(VueMask)
Vue.use(VueCardFormat)

if (window && window.VUE_APP_GOOGLE_ANALYTICS_ID) {
  Vue.use(
    VueGtag,
    {
      config: { id: window.VUE_APP_GOOGLE_ANALYTICS_ID }
    },
    router
  )
}

if (window && document) {
  const vh = window.innerHeight * 0.01
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`)

  window.addEventListener('resize', () => {
    // We execute the same script as before
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  })
}

const currencyOptions = {
  globalOptions: { currency: 'USD' }
}
Vue.use(VueCurrencyInput, currencyOptions)

/* Set the access for quickest possible auth access */
const accessToken = localStorage.getItem('blytzpay_access_token')
axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`

if (window.VUE_APP_ENV !== 'localhost') {
  Sentry.init({
    Vue: Vue,
    dsn: window.VUE_APP_SENTRY_CONSUMER_UI,
    ignoreErrors: ['LocalError', 'NavigationDuplicated']
  })
}

new Vue({
  router,
  store,
  i18n,
  beforeCreate() {
    this.$store.dispatch('rehydrate')
  },
  render: h => h(App)
}).$mount('#app')
