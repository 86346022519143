













import { Component, Vue } from 'vue-property-decorator'
import NavigationItem from './NavigationItem.vue'
import navLinks from './nav-links'

@Component({
  components: {
    NavigationItem
  }
})
export default class NavigationMenu extends Vue {
  navLinks = navLinks()
}
