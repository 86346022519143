import mitt from 'mitt'
import _Vue from 'vue'

export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void

export interface PluginObject<T> {
  install: PluginFunction<T>
  [key: string]: any
}

export const eventBus = mitt()

export default {
  install<PluginFunction>(Vue: typeof _Vue) {
    Vue.prototype.$eventBus = eventBus
  }
}
