import {
  IconDefinition,
  faCalendarAlt,
  faCreditCard
} from '@fortawesome/free-regular-svg-icons'
import {
  faBars,
  faCaretDown,
  faCaretRight,
  faChevronLeft,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faFileInvoiceDollar,
  faHandshake,
  faMoneyBill,
  faPen,
  faPlus,
  faRedoAlt,
  faSignOutAlt,
  faTimesCircle,
  faUniversity,
  faWallet,
  faBarcode,
} from '@fortawesome/free-solid-svg-icons'
import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa
} from '@fortawesome/free-brands-svg-icons'
import { faCommentsAlt, faUsdCircle } from '@fortawesome/pro-solid-svg-icons'

import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'

const library: IconDefinition[] = [
  faCreditCard,
  faCaretRight,
  faPen,
  faCalendarAlt,
  faRedoAlt,
  faBars,
  faUsdCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faFileInvoiceDollar,
  faCommentsAlt,
  faSpinnerThird,
  faSignOutAlt,
  faWallet,
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
  faSpinnerThird,
  faUniversity,
  faTimesCircle,
  faPlus,
  faHandshake,
  faCaretDown,
  faMoneyBill,
  faChevronLeft,
  faBarcode,
]

export default library
