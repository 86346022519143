import { AuthState } from '@/utils/interfaces'
import moment from 'moment'

export default function(): AuthState {
  return {
    accessToken: null,
    refreshToken: null,
    lastActivity: moment()
      .utc()
      .unix(),
    isInactive: false,
    profile: null,
    customers: null,
    heartbeat: null
  }
}
