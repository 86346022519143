














import { Component, Vue } from 'vue-property-decorator'
import { LimitedMerchant } from '@/utils/interfaces'
import { mapGetters } from 'vuex'

import BlytzLogo from '@/components/Elements/Logo/BlytzLogo.vue'

@Component({
  computed: {
    ...mapGetters({
      merchant: 'currentMerchant'
    })
  },
  components: {
    BlytzLogo
  }
})
export default class Logo extends Vue {
  merchant!: LimitedMerchant

  get merchantName() {
    return this.merchant.name?.substring(0, 2)
  }
}
