import endpoints from '@/utils/endpoints'
import store from '@/store'
import axios from 'axios'
import { Dict } from './interfaces'

axios.defaults.baseURL = window
  ? window.VUE_APP_API_URL
  : process.env.VUE_APP_API_URL

axios.interceptors.request.use(
  config => {
    // Do something before request is sent
    store.commit('incrementBusyCount')
    return config
  },

  error => {
    store.commit('decrementBusyCount')
    // Do something with request error
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    if (response.config.url != endpoints.createTokenRefresh()) {
      store.commit('setLastActivity')
    }

    store.commit('decrementBusyCount')
    return response
  },
  error => {
    store.commit('decrementBusyCount')

    if (error?.response?.status === 401) {
      store.dispatch('logout')
    }

    return Promise.reject(error)
  }
)

export default axios

export const anonymousAxios = axios.create({
  baseURL: axios.defaults.baseURL,
  transformRequest: [
    (data, headers: Dict) => {
      // or just the auth header
      delete headers.common.Authorization
      return data
    },
    ...(axios.defaults.transformRequest as any[])
  ]
})
