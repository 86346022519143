import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import getDefaultState from './defaultState'

const state = getDefaultState()

// Attempt to get tokens from local storage
const accessToken = localStorage.getItem('blytzpay_access_token')
const refreshToken = localStorage.getItem('blytzpay_refresh_token')

if (accessToken) {
  state['accessToken'] = accessToken
  state['refreshToken'] = refreshToken
}

const store: any = {
  state: () => state,
  mutations,
  actions,
  getters
}

export default store
