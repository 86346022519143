import { Account, DataState, LimitedMerchant } from '@/utils/interfaces'

export default function(): DataState {
  return {
    openedDrawers: [] as string[],
    busyCount: 0,
    merchants: new Map<string, LimitedMerchant>(),
    currentMerchant: null,
    currentCustomer: null,
    currentInvoice: null,
    customers: [],
    accounts: new Map<string, Account>(),
    currentAccount: null,
    payments: [],
    currentPayment: null,
    binInfo: null,
    currentAutopay: null
  }
}
