import { DataGetters, DataState } from '@/utils/interfaces'

const getters: DataGetters = {
  drawers: (state: DataState) => (): string[] => {
    return state.openedDrawers
  },

  hasDrawer: (state: DataState) => (id: string) => {
    return state.openedDrawers.includes(id)
  },

  busyCount: (state: DataState) => () => {
    return state.busyCount
  },

  isBusy: (state: DataState) => {
    return state.busyCount >= 1
  },

  merchants: (state: DataState) => {
    return state.merchants
  },

  currentMerchant: (state: DataState) => {
    return state.currentMerchant
  },

  accounts: (state: DataState) => {
    return state.accounts
  },

  currentCustomer: (state: DataState) => {
    return state.currentCustomer
  },

  getCustomerByAccountID: (state: DataState): Function => (
    accountID: string
  ) => {
    return (
      state.customers.find(customer =>
        customer.accounts.find(account => account.id === accountID)
      ) ?? {}
    )
  },

  currentAccount: (state: DataState) => {
    return state.currentAccount
  },

  currentInvoice: (state: DataState) => {
    return state.currentInvoice
  },

  customers: (state: DataState) => {
    return state.customers
  },

  payments: (state: DataState) => {
    return state.payments
  },

  currentPayment: (state: DataState) => {
    return state.currentPayment
  },

  binInfo: (state: DataState) => {
    return state.binInfo
  }
}

export default getters
