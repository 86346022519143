import axios, { anonymousAxios } from '@/utils/blytz-axios'
import { Account, Dict, Payment2, PaymentDetail } from '@/utils/interfaces'

import endpoints from '@/utils/endpoints'
import { sortBy } from 'lodash-es'

const actions = {
  async fetchMerchant(
    { commit, getters }: { commit: Function; getters: any },
    id: string,
    force = false
  ) {
    let merchant = getters.merchants.get(id)
    if (getters.currentMerchant?.id === id && !force)
      return getters.currentMerchant

    if (!merchant) {
      commit('setCurrentMerchant', null)
      const response = await anonymousAxios.get(endpoints.merchantInfo(id))
      merchant = response.data
    }

    commit('setCurrentMerchant', merchant)
    return merchant
  },

  async fetchAccount(
    { dispatch, commit, getters }: { dispatch: Function; commit: Function; getters: any },
    id: string
  ) {
    const response = await axios.get(endpoints.retrieveAccountRoot(id))

    // at the moment to get the customer on the getter 'getCustomerByAccountID'
    // the list 'customers' is empty, in order to populate the data
    // we need to invoke 'getCustomersAndMerchants'

    if (!getters.customers.length) { 
      await dispatch('getCustomersAndMerchants')
    }
    const customer = getters.getCustomerByAccountID(response.data.id)
    commit('setCurrentCustomer', customer)

    const account = new Account({
      ...response.data,
      customer
    })

    if (account.current_invoice) {
      commit('setCurrentInvoice', account.current_invoice)
    }

    commit('setCurrentAccount', account)
    return account
  },

  async patchAccount(
    { commit, getters }: { commit: Function; getters: any },
    { id, values }: { id: string; values: Dict }
  ) {
    const response = await axios.patch(
      endpoints.retrieveAccountRoot(id),
      values
    )

    const customer = getters.getCustomerByAccountID(id)
    commit('setCurrentCustomer', customer)

    const account = new Account({ ...response.data, customer })

    commit('setCurrentAccount', account)
    return account
  },

  async fetchPayments(
    { commit, getters }: { commit: Function; getters: any },
    { page = 1, count = 100 }
  ) {
    const response = await axios.get(endpoints.getPayments(), {
      params: { page: page, page_size: count }
    })

    const payments = response.data.results.map((data: Dict) => {
      const merchant = getters.merchants.get(data.merchant)
      data.merchant = merchant

      const payment = new Payment2(data)

      return payment
    })

    sortBy(payments, [
      payment => payment.processedTime,
      payment => payment.created
    ])

    commit('setPayments', payments)
    return payments
  },

  async fetchPayment(
    { commit, dispatch }: { commit: Function; dispatch: Function },
    id: string
  ) {
    commit('setCurrentPayment', null)
    const response = await axios.get(endpoints.getPaymentDetail(id))
    const data = response.data

    const merchant = await dispatch('fetchMerchant', data.merchant)
    data.merchant = merchant

    const payment = new PaymentDetail(data)

    commit('setCurrentPayment', payment)
    return payment
  },

  async fetchBinInfo({ commit }: { commit: Function }, bin: string) {
    const response = await axios.get(endpoints.retrieveBinInfo(bin))

    commit('setBinInfo', response.data)
    return response
  },

  async updateAutopay(
    { commit }: { commit: Function; getters: any },
    { id, values }: { id: string; values: Dict }
  ) {
    const response = await axios.post(endpoints.changeAutopay(id), values)

    const updatedAutopayFields = { ...response.data }
    commit('setCurrentAutopayFields', updatedAutopayFields)

    return updatedAutopayFields
  }
}

export default actions
