import {
  Account,
  Customer,
  DataState,
  Dict,
  Invoice,
  LimitedMerchant,
  Payment2
} from '@/utils/interfaces'
import getDefaultState from './defaultState'

const mutations = {
  openDrawer(state: DataState, id: string) {
    state.openedDrawers.push(id)
  },

  closeDrawer(state: DataState, id: string) {
    state.openedDrawers = state.openedDrawers.filter(
      (name: string) => name !== id
    )
  },

  popDrawer(state: DataState) {
    state.openedDrawers.pop()
  },

  closeAllDrawers(state: DataState) {
    state.openedDrawers = []
  },

  incrementBusyCount(state: DataState) {
    state.busyCount += 1
  },

  decrementBusyCount(state: DataState) {
    state.busyCount -= 1
  },

  resetState(state: DataState) {
    Object.assign(state, getDefaultState())
  },

  setCustomers(state: DataState, customers: Customer[]) {
    state.customers = customers
  },

  setCurrentCustomer(state: DataState, customer: Customer) {
    state.currentCustomer = customer
  },

  setAccounts(state: DataState, accounts: Map<string, Account>) {
    state.accounts = accounts
  },

  setCurrentAccount(state: DataState, account: Account) {
    state.currentAccount = account
  },

  setCurrentInvoice(state: DataState, invoice: Invoice) {
    state.currentInvoice = invoice
  },

  setMerchants(state: DataState, merchants: Map<string, LimitedMerchant>) {
    state.merchants = merchants
  },

  setCurrentMerchant(state: DataState, merchant: LimitedMerchant) {
    state.currentMerchant = merchant
  },

  setPayments(state: DataState, payments: Payment2[]) {
    state.payments = payments
  },

  setCurrentPayment(state: DataState, payment: Payment2 | null) {
    state.currentPayment = payment
  },

  setBinInfo(state: DataState, binInfo: Dict | null) {
    state.binInfo = binInfo
  },

  setCurrentAutopayFields(state: DataState, autopayFields: Dict) {
    state.currentAutopay = autopayFields
  }
}

export default mutations
